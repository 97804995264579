import React, { Component } from "react";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import Select from 'react-select';

class EditMarketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      response: "",
      marketplaceField: "",
      marketplaceScopes: "",
      convertedMarketplaceScopes: "",
      saasOrgMarketplace: "",
      dataFetched: false,
      clientId: "",
      secretId: "",
      appId: "",
      appSlug: "",
      redirectURL: "",
      selectedScopeValues: [],
      createSaaSOrgMarketplaceResponse: "",
      isEditClicked: false,
      updateSaaSOrgMarketplaceResponse: "",
      jwt: "",
      isError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    console.log(
      "componentDidMount edit marketplace : CreateOrg response: ",
      this.props.location.createOrgResponse
    );
    console.log(
      "componentDidMount edit marketplace : Marketplace response: ",
      this.props.location.marketplace
    );
    console.log(
      "componentDidMount edit marketplace : createSaaSOrgMarketplaceResponse response: ",
      this.props.location.createSaaSOrgMarketplaceResponse
    );

    console.log(
      "componentDidMount edit marketplace : marketplaceScopes response: ",
      this.props.location.convertedMarketplaceScopes
    );

    console.log(
      "componentDidMount edit marketplace : selected scopes response: ",
      this.props.location.createSaaSOrgMarketplaceResponse.data.scopeValues.split(",")
    );

    this.setState({
      selectedScopeValues: this.test(this.props.location.createSaaSOrgMarketplaceResponse.data.scopeValues.split(
        ",")),
      clientId: this.props.location.createSaaSOrgMarketplaceResponse.data
        .clientId,
      secretId: this.props.location.createSaaSOrgMarketplaceResponse.data
        .secretId,
      appId: this.props.location.createSaaSOrgMarketplaceResponse.data.appId,
      redirectURL: this.props.location.createSaaSOrgMarketplaceResponse.data.redirectURL,
      appSlug: this.props.location.createSaaSOrgMarketplaceResponse.data.appSlug,
      jwt: this.props.location.createSaaSOrgMarketplaceResponse.jwt,
     convertedMarketplaceScopes: this.props.location.convertedMarketplaceScopes,
    });

    fetch(
      API_BASE_URL +
        "marketplace-field/get-marketplace-field-by-marketplace-id/" +
        this.props.location.marketplace.id,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.location.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          marketplaceField: responseJson,
          dataFetched: true,
        });
        console.log(
          "get-marketplace-field-by-marketplace-id Response: ",
          responseJson
        );

        fetch(
          API_BASE_URL +
            `saas-org-marketplace/get-saas-org-marketplace-by-orgId-marketplaceId-and-appSlug/${this.props.location.createOrgResponse.data.id}/${this.props.location.marketplace.id}/${this.props.location.createSaaSOrgMarketplaceResponse.data.appSlug}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.location.token}`,
            },
          }
        )
          .then((response) => response.json())
          .then((responseJson) => {
            this.setState({
              saasOrgMarketplace: responseJson,
              dataFetched: true,
            });
            console.log("Response: ", responseJson);
            //this.setState({ selectedScopeValues: this.state.marketplaceField.data.scopeValues.split(',') })
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }


  test = marketplaceScopes => {
    console.log("Inside test: ", marketplaceScopes)
    
    marketplaceScopes = marketplaceScopes.map(ms => ({label: ms, value: this.props.location.marketplace.id}))
    console.log("Converted MarketplaceScopes", marketplaceScopes)
    this.setState({
      marketplaceScopes: marketplaceScopes
    })
    return marketplaceScopes
  }

  onScopesChange = selectedScopes => {
    console.log("selected scopes: ", selectedScopes)
    const value = [];
    selectedScopes.map(ss => value.push(ss.label) )
    console.log("value: ", value)
    this.setState({
      selectedScopeValues: selectedScopes || [],
      selectedScopeNames: value || [],
      marketplaceScopes: selectedScopes || []
    })
  }

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    console.log("====> ", value);
    this.setState({ selectedScopeValues: value });
  };

  onEditClick = () => {
    console.log("inside onEditClick!!");
    this.setState({
      isEditClicked: true,
    });
  };

  onRefreshJWTClick = () => {
    console.log("Refresh click");
    fetch(
      API_BASE_URL +
        `saas-org-sgapi-creds/get-refresh-dev-jwt-by-orgId-and-marketplaceId/${this.props.location.createOrgResponse.data.id}/${this.props.location.marketplace.id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.location.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("refresh JWT API response: ", responseJson);
        this.setState({
          jwt: responseJson.data.jwt,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  validateRedirectURL = (redirectURL) => {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    if(!redirectURL || /^\s*$/.test(redirectURL)){
      return true;
    }
    if (redirectURL.includes('localhost') || redirectURL.includes('127.0. 0.1')){
      return true;
    }
    return urlRegex.test(String(redirectURL).toLowerCase())
  } 

  validateInputs = () => {
    if(!this.state.appSlug){
      console.log("App Slug must not be empty.")
      this.setState({
        isError: true,
        errorMessage: ` ${this.state.marketplaceField.data.appSlug} must not be empty.`
      })
      return false;
  } else if(!this.state.clientId){
    this.setState({
      isError: true,
      errorMessage: ` ${this.state.marketplaceField.data.clientId} must not be empty.`
    })
    return false;
} else if(!this.state.secretId){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.secretId} must not be empty.`
  })
  return false;
}else if(!this.state.appId){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.appId} must not be empty.`
  })
  return false;
}else if(this.state.selectedScopeValues.length <= 0){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.scopes} must not be empty.`
  })
  return false;
}else if (!this.validateRedirectURL(this.state.redirectURL)){
  this.setState({
    isError: true,
    errorMessage:` Invalid Redirect URL. Prefix with https/http required.`
  })
  return false;
}

return true;
  
}

getScopeValues = (marketplaceScopes) => {

  const values = [];
  marketplaceScopes.map(ss => values.push(ss.label) )
  return values.toString();
}

  onUpdateClick = () => {

    function hasUpperCase(str) {
      return str.toLowerCase() !== str;
  }

  if(!this.validateInputs()){
    window.scrollTo(0, 0);
    return;
  }

    if(hasUpperCase(this.state.appSlug)){
      console.log("App Slug must be in all lower case.")
      this.setState({
        isError: true,
        errorMessage: "App Slug must be in all lower case."
      })
      window.scrollTo(0, 0);
      return;
    }

    if((/\s/.test(this.state.appSlug))){
      console.log("App Slug must be without any space character.")
      this.setState({
        isError: true,
        errorMessage: "App Slug must be without any space character."
      })
      window.scrollTo(0, 0);
      return;
    }

    this.setState({
      isError: false
    })

    let request = {
      id: this.props.location.createSaaSOrgMarketplaceResponse.data.id,
      appSlug: this.state.appSlug,
      appId: this.state.appId,
      clientId: this.state.clientId,
      secretId: this.state.secretId,
      scopeValues: this.getScopeValues(this.state.marketplaceScopes),
      saasOrganisation: {
        id: this.props.location.createOrgResponse.data.id,
      },
      marketplace: {
        id: this.props.location.marketplace.id,
      },
      callbackURL: this.props.location.createSaaSOrgMarketplaceResponse.data
        .callbackURL,
        redirectURL: this.state.redirectURL,

    };

    console.log("Request: ", JSON.stringify(request));

    fetch(API_BASE_URL + "saas-org-marketplace/update-saas-org-marketplace", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.location.token}`,
      },
      body: JSON.stringify(request),
    })
      .then(
        (response) => {
          if (response.ok) {
            this.setState({
              message: "SaaS Org Marketplace updated successfully.",
            });
          }
          return response.json();
        },
        (error) => console.log("An error occurred.", error)
      )
      .then((responseJson) => {
        this.setState({
          updateSaaSOrgMarketplaceResponse: responseJson,
        });
      })
      .then(() => {
        console.log("Response: ", this.state.updateSaaSOrgMarketplaceResponse);

        this.props.history.push({
          pathname: "/",
          createOrgResponse: this.props.location.createOrgResponse,
          marketplace: this.props.location.marketplace,
          createSaaSOrgMarketplaceResponse: this.state
            .createSaaSOrgMarketplaceResponse,
          token: this.props.location.token,
          updateSaaSOrgMarketplaceResponse: this.state
            .updateSaaSOrgMarketplaceResponse,
        });
      });
  };

  render() {

    const errorStyle = {
      color: 'red',
    };

    return (
      <div>
        <main>
          <h1>Edit Marketplace Details</h1>

          {this.state.dataFetched === true ? (
            <div>
              <p>Callback URL: </p>
              <div className="row">
                <pre className="col-12 text-light bg-dark p-4">
                  {
                    this.props.location.createSaaSOrgMarketplaceResponse.data
                      .callbackURL
                  }
                </pre>
              </div>
            

              <FormGroup>
              {this.state.isError === true && (
                  <div>
              <FormControl>
              <FormHelperText id="generic-help-text" style={errorStyle}>
                  {this.state.errorMessage}
               </FormHelperText>
               </FormControl>
               <br/><br/>
               </div>
              )}
                <FormControl>
                <InputLabel htmlFor="app-slug">
                    {this.state.marketplaceField.data.appSlug}
                  </InputLabel>
                  <Input
                    id="app-slug"
                    disabled={!(this.state.isEditClicked === true)}
                    aria-describedby="app-slug-help-text"
                    value={this.state.appSlug}
                    onChange={(e) => this.setState({ appSlug: e.target.value })}
                  />
                  <FormHelperText id="app-slug-help-text">
                    Please provide a short and memorable desciption for the app,
                    e.g. hubspot_dev or hbDev (no spaces and all lower case)
                  </FormHelperText>
                  {/* {this.state.isError === true && (
              <FormHelperText id="app-slug-help-text" style={errorStyle}>
                  {this.state.errorMessage}
               </FormHelperText>
              )} */}
               </FormControl>
               <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="client-id">
                    {this.state.marketplaceField.data.clientId}
                  </InputLabel>
                  <Input
                    disabled={!(this.state.isEditClicked === true)}
                    id="client-id"
                    aria-describedby="client-id-help-text"
                    value={this.state.clientId}
                    onChange={(e) =>
                      this.setState({ clientId: e.target.value })
                    }
                  />
                  <FormHelperText id="client-id-help-text">
                    Please provide the{" "}
                    {this.state.marketplaceField.data.clientId} you have got
                    from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="secret-id">
                    {this.state.marketplaceField.data.secretId}
                  </InputLabel>
                  <Input
                    disabled={!(this.state.isEditClicked === true)}
                    aria-describedby="secret-id-help-text"
                    value={this.state.secretId}
                    onChange={(e) =>
                      this.setState({ secretId: e.target.value })
                    }
                  />
                  <FormHelperText id="client-id-help-text">
                    Please provide the{" "}
                    {this.state.marketplaceField.data.secretId} you have got
                    from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="app-id">
                    {this.state.marketplaceField.data.appId}
                  </InputLabel>
                  <Input
                    disabled={!(this.state.isEditClicked === true)}
                    id="app-id"
                    aria-describedby="app-id-help-text"
                    value={this.state.appId}
                    onChange={(e) => this.setState({ appId: e.target.value })}
                  />
                  <FormHelperText id="app-id-help-text">
                    Please provide the {this.state.marketplaceField.data.appId}{" "}
                    you have got from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="redirect-url">
                    Redirect URL
                  </InputLabel>
                  <Input
                    id="redirect-url"
                    disabled={!(this.state.isEditClicked === true)}
                    aria-describedby="redirect-url-help-text"
                    value={this.state.redirectURL}
                    onChange={(e) => this.setState({ redirectURL: e.target.value })}
                  />
                 
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="select-multiple-scopes">
                    {this.state.marketplaceField.data.scopes}
                  </InputLabel>

                  <Select
                        isMulti
                        isDisabled={!(this.state.isEditClicked === true)}
                        value={this.state.marketplaceScopes}
                        onChange={this.onScopesChange}  
                        placeholder={'Enter Scopes Here!'}
                        options ={this.state.convertedMarketplaceScopes}
                    />

                  {/* <Select
                    multiple
                    disabled={!(this.state.isEditClicked === true)}
                    native
                    //change below to selected scopevalues in compo did set selectedscopevalues so that on edit we can add or remove from selected scope values
                    value={this.state.selectedScopeValues}
                    onChange={this.handleChangeMultiple}
                    inputProps={{
                      id: "select-multiple-scopes",
                    }}
                  >
                    {this.state.marketplaceField.data.scopeValues
                      .split(",")
                      .map((scopeValue) => (
                        <option key={scopeValue} value={scopeValue}>
                          {scopeValue}
                        </option>
                      ))}
                  </Select> */}
                </FormControl>
                <br />
                <br />
                <br />
                <FormControl>
                  {this.state.isEditClicked === true ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onUpdateClick}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onEditClick}
                    >
                      Edit
                    </Button>
                  )}
                </FormControl>
                <FormControl></FormControl>
                <br />
                <br />
              </FormGroup>

              <h2>{this.state.message}</h2>
            </div>
          ) : (
            <div></div>
          )}
        </main>
      </div>
    );
  }
}

export default withRouter(EditMarketplace);