import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  FormGroup,
  Button,
} from "@material-ui/core";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function UpdateOrganisation(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  //const { name, email } = user;
  const [orgName, setOrgName] = useState("");
  //const [onSaaSAdminLoginResponse, setOnSaaSAdminLoginResponse] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [linkSaaSAdminToOrgResponse, setLinkSaaSAdminToOrgResponse] = useState(
    ""
  );
  const [onSaaSAdminLoginResponse, setOnSaaSAdminLoginResponse] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);



  useEffect(() => {
    console.log("org details: ", props.history.location.organisationDetails)
    gettingUserInfo();
  }, [user]);

  const gettingUserInfo = async () => {
    console.log("env: ", API_BASE_URL);
    if (user !== undefined) {
      const token = await getAccessTokenSilently();
      /** 
          if admin not saved then save it
          check if user linked to org 
      */
      let request = {
        email: user.email,
        nickname: user.nickname,
      };
      fetch(API_BASE_URL + "saas-admin/on-saas-admin-login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(
            "saas-admin/on-saas-admin-login Response: ",
            responseJson
          );
          setOnSaaSAdminLoginResponse(responseJson)
          setOrgName(responseJson.data.saasOrganisation.orgName)
          setEmail(responseJson.data.saasOrganisation.email)
          setPhone(responseJson.data.saasOrganisation.phone)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const  validateInputs = () => {

    function isValidPhone(phoneNumber) {
      var found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
      if(found > -1) {
          return true;
      }
      else {
          return false;
      }
  }

    console.log("Inside validate inputs, org:", orgName )
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

    if(!orgName){
      setIsError(true)
      setErrorMessage(`Org Name must not be empty.`)
      return false;
  } else  if(!email){
    setIsError(true)
    setErrorMessage(`Email must not be empty.`)
    return false;
}else if (!emailRegex.test(String(email).toLowerCase())){
  setIsError(true)
  setErrorMessage(`Invalid email id.`)
  return false;
} else if(!phone){
  setIsError(true)
  setErrorMessage(`Phone must not be empty.`)
  return false;
}  else if (!phoneRegex.test(String(phone))){
  setIsError(true)
  setErrorMessage(`Invalid phone number.`)
  return false;
} 

return true;
}

  const onUpdateClick = async () => {
    let request = {
      id: onSaaSAdminLoginResponse.data.saasOrganisation.id,
      orgName: orgName,
      email: email,
      phone: phone
    };
    if(!validateInputs()){
      window.scrollTo(0, 0);
      return;
    }
    const token = await getAccessTokenSilently();
    console.log(JSON.stringify(request));
    fetch(API_BASE_URL + "saas-org/update-saas-org", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    })
      .then(
        (response) => {
          if (response.ok) {
            setMessage("SaaS Organisation created successfully.");
          }
          return response.json();
        },
        (error) => console.log("An error occurred.", error)
      )
      
      .then(() => {
        console.log("update org response: ", response);
        props.history.push({
          pathname: '/'
        })
      
      });
  };

  const onEditClick = () => {
    console.log("inside onEditClick!! onSaaSAdminLoginResponse: ", onSaaSAdminLoginResponse);
    setIsEditClicked(true);
  };

  const errorStyle = {
    color: 'red',
  };


  return (
    <div>
        {user !== undefined &&  (
      <main>
      <h1>Update Organisation Details</h1>
      <FormGroup>
      {isError === true && (
                  <div>
              <FormControl>
              <FormHelperText id="generic-help-text" style={errorStyle}>
                  {errorMessage}
               </FormHelperText>
               </FormControl>
               <br/><br/>
               </div>
              )}
        <FormControl>
          <InputLabel htmlFor="org-name">Organisation Name</InputLabel>
          <Input
            id="org-name"
            disabled={!(isEditClicked === true)}
            aria-describedby="org-name-help-text"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />
          <FormHelperText id="org-name-help-text"></FormHelperText>
        </FormControl>
        <br />
        <FormControl>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            disabled={!(isEditClicked === true)}
            aria-describedby="email-help-text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormHelperText id="email-help-text">
            We'll never share your email id.
          </FormHelperText>
        </FormControl>
        <br />
        <FormControl>
          <InputLabel htmlFor="phone">Phone</InputLabel>
          <Input
            id="phone"
            disabled={!(isEditClicked === true)}
            aria-describedby="phone-help-text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <FormHelperText id="phone-help-text">
            We'll never share your phone number.
          </FormHelperText>
        </FormControl>
        <br />
        <br />
        <br />
        <br />
        <br />

        <FormControl>
        {isEditClicked === true ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onUpdateClick}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onEditClick}
                    >
                      Edit
                    </Button>
                  )}
        </FormControl>
      </FormGroup>
      <h2>{message}</h2>
    </main>
      )}
    </div>
  );
}

export default withRouter(UpdateOrganisation);
