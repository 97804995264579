import React, { Component } from "react";
import { API_BASE_URL } from "../util/constants";
import { Button, Snackbar } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

// function Alert(props) {
//   return <MuiAlert variant="filled" {...props} />;
// }


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});
class SelectMarketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      response: "",
      marketplaces: "",
      dataFetched: false,
      open: false,
     // classes: useStyles()
    };
  }
  

  componentDidMount() {
    fetch(API_BASE_URL + "marketplace/get-all-marketplaces", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.location.token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Get all marketplces response: ", responseJson);
        this.setState({
          marketplaces: responseJson,
          dataFetched: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  onLearnMoreClick = (marketplace) => (e) => {

    window.open(marketplace.website, '_blank');

  }

  onMarketplaceClick = (marketplace) => async (e) => {
    console.log("Marketplace --> ", marketplace);
    console.log("CreateOrg response: ", this.props.location.response);

    if (marketplace.marketplaceField === null)
     {
      const body = {
        to: this.props.location.response.data.email,
        subject: `getting you connected to ${marketplace.name}`,
        text: `Hi ${this.props.location.response.data.email}
         Thank you for your interest to integrate with ${marketplace.name}.
         We're sorry this isn't available immediately, but we'll be in touch within 24 hours with an expected launch date. 
        Cheers,
        Manish`,
        html: `<body>
          <p>Hi ${this.props.location.response.data.email}</p>
        <p>Thank you for your interest to integrate with ${marketplace.name}.</p>
        <p>We're sorry this isn't available immediately, but we'll be in touch within 24 hours with an expected launch date.</p>
        <p>Cheers,
        Manish</p>
        </body>`,
      };
      this.setState({ open: true });
      await fetch(API_BASE_URL + "email/send-email", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.location.token}`,
        },
        body: JSON.stringify(body),
      });
    } else {

      this.props.history.push({
        pathname: "/add-marketplace/" + marketplace.name,
        createOrgResponse: this.props.location.response,
        marketplace: marketplace,
        token: this.props.location.token,
      });
    }
  };

  render() {
    const containerStyle = {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start", // if you want to fill rows left to right
      columns: "2 auto",
    };

    const childStyle = {
      width: "50%",
    };

    const cardStyle = {
      // maxWidth: 345,
    };

    return (
      <div>
        <main>
          <h1>Select Marketplace</h1>
          <Snackbar
            open={this.state.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="info">
              Sorry, this marketplace is not available yet! We'll contact you
              with an ETA
            </Alert>
          </Snackbar>
          {this.state.dataFetched === true ? (
            <div style={containerStyle}>
              {this.state.marketplaces.data.map((marketplace, i) => (
                <div>
                  <div style={childStyle} key={marketplace.id}>

                  <br/><br/>
                    <Card className={cardStyle} >
                      <CardActionArea onClick={this.onMarketplaceClick(marketplace)} >
                        <CardMedia
                          component="img"
                          alt={marketplace.name}
                          height="140"
                          image={marketplace.imageUrl}
                          title={marketplace.name}
                        />
                        <CardContent>
                          {/* <Typography gutterBottom variant="h5" component="h2">
                              {marketplace.name}
                          </Typography> */}
                          {/* <Typography variant="body2" color="textSecondary" component="p">
                          {marketplace.description}
                          </Typography> */}
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary"
                        onClick={this.onMarketplaceClick(marketplace)}
                        >
                          Add
                        </Button>
                      
                        <Button size="small" color="primary"
                        onClick={this.onLearnMoreClick(marketplace)}
                        >
                          Learn More
                        </Button>

                      </CardActions>
                    </Card>
{/*                         
                        <Button
                      onClick={this.onMarketplaceClick(marketplace)}
                      color="primary"
                      variant="contained"
                    >
                      {marketplace.name}
                    </Button> */}
                    <br />
                    <br />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </main>
      </div>
    );
  }
}

export default withRouter(SelectMarketplace);
