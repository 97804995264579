import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { NavBar, Footer, Loading } from './components';
import { Home } from './views';
import ProtectedRoute from './auth/protected-route';
import { GA_TRACKING_ID, STRIPE_KEY } from './util/constants';
import ReactGA from 'react-ga';
import "./App.css";
import CompleteProfile from "./views/complete-profile";
import SelectMarketplace from "./views/select-marketplace";
import AddMarketplace from "./views/add-marketplace";
import EditMarketplace from "./views/edit-marketplace";
import RouteChangeTracker from "./components/route-change-tracker";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import billing from "./views/billing";
import settings from "./views/settings";
import updateOrganisation from "./views/update-organisation";
import saasCreds from "./views/saas-creds";
import { withRouter } from "react-router-dom";
import addAuthProvider from "./views/add-auth-provider"
import connectedUsers from './views/connected-users';

// import sgSwagger from "./views/sg-swagger";

const stripePromise = loadStripe(STRIPE_KEY);

ReactGA.initialize(GA_TRACKING_ID);

const App = () => {
  const { isLoading } = useAuth0();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="app" className="d-flex flex-column h-100">
      <Elements stripe={stripePromise}>
        <RouteChangeTracker />
        <NavBar />
        <div className="container flex-grow-1">
          <Switch>
            <Route path="/" exact component={Home} />
            <ProtectedRoute path="/marketplaces" component={settings} />
            <ProtectedRoute path="/organisation" component={updateOrganisation} />
            <ProtectedRoute path="/creds" component={saasCreds} />
            <ProtectedRoute path="/connected-users" component={connectedUsers} />


            <ProtectedRoute
              path="/complete-profile"
              component={CompleteProfile}
            />
            <ProtectedRoute
              path="/select-marketplace"
              component={SelectMarketplace}
            />
            <ProtectedRoute
              path="/add-marketplace"
              component={AddMarketplace}
            />
            <ProtectedRoute
              path="/edit-marketplace"
              component={EditMarketplace}
            />
            <ProtectedRoute
              path="/update-organisation"
              component={updateOrganisation}
            />
            <ProtectedRoute
              path="/add-auth-provider"
              component={addAuthProvider}
            />
            <ProtectedRoute path="/billing" component={billing} />
            {/* <ProtectedRoute path="/sg-swagger" component={sgSwagger} /> */}
          </Switch>
        </div>
        <Footer />
      </Elements>
    </div>
  );
};

export default withRouter(App);
