const CURRENT_ENV = "production";
const API_BASE_URL =
  CURRENT_ENV === "production"
    ? "https://api-dot-stackgo-300922.ts.r.appspot.com/api/"
    : "http://localhost:8080/api/";
const BILLING_BASE_URL =
  CURRENT_ENV === "production"
    ? "https://api-dot-stackgo-300922.ts.r.appspot.com/billing/"
    : "http://localhost:8080/billing/";

const STRIPE_KEY =
  CURRENT_ENV === "production"
    ? "pk_live_51HhLUIBu42BhVYfNOc3sFv8N2BNoTuXlPOxjpsyT63OmxCiZx7VhocDPBoINmNjaemqfooD1CRssRtF4ht5B0Fsn00gXFpRHGB"
    : "pk_test_51HhLUIBu42BhVYfNlbRbGk053p7N178etHKpfmvBN0tBWJDeQGXnh4WK9hgzxF4f52ofoCiQXFhZp7s2g0B1swW9004y0NHWfW";

const GA_TRACKING_ID =
  CURRENT_ENV === "production" ? "UA-175182800-1" : "UA-191702154-1";

export { API_BASE_URL, BILLING_BASE_URL, STRIPE_KEY, GA_TRACKING_ID };
