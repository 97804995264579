import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { BILLING_BASE_URL } from "../util/constants";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";

function StripeSubscriptionButton(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { getAccessTokenSilently, user } = useAuth0();
  const [quantity, setQuantity] = useState(1);
  const redirectToStripe = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    console.log("user: ", user);
    const { email } = user;
    console.log("from redirect to stripe", email);
    const token = await getAccessTokenSilently();
    const res = await fetch(
      BILLING_BASE_URL +
        "create-customer-subscription-session" +
        `?email=${encodeURIComponent(email)}&quantity=${encodeURIComponent(
          quantity
        )}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    const resJson = await res.json();
    console.log(resJson);
    // get session id

    stripe.redirectToCheckout({
      sessionId: resJson.sessionId,
    });
  };

  return (
    <div>
      <p>Quantity: {quantity}</p>
      <Button onClick={() => setQuantity((quantity + 1) % 5)}>Add</Button>
      <Button onClick={redirectToStripe}>Subscribe</Button>
    </div>
  );
}

export default StripeSubscriptionButton;
