import React, { Component } from "react";
import { API_BASE_URL } from "../util/constants";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Select from 'react-select';

class AddMarketplace extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: "",
      response: "",
      marketplaceField: "",
      marketplaceScopes: "",
      dataFetched: false,
      isScopesFetched: false,
      clientId: "",
      secretId: "",
      redirectURL:"",
      appId: "",
      appSlug: "",
      selectedScopeValues: [],
      selectedScopeNames: [],
      createSaaSOrgMarketplaceResponse: "",
      isError: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    console.log(
      "componentDidMount add marketplace : CreateOrg response: ",
      this.props.location.createOrgResponse
    );
    console.log(
      "componentDidMount add marketplace : Marketplace response: ",
      this.props.location.marketplace
    );

    fetch(
      API_BASE_URL +
        "marketplace-field/get-marketplace-field-by-marketplace-id/" +
        this.props.location.marketplace.id,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.location.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          marketplaceField: responseJson,
          dataFetched: true,
        });
        console.log("Response: ", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });

      fetch(
        API_BASE_URL +
          "marketplace-scope/get-marketplace-scopes-by-marketplace-id/" +
          this.props.location.marketplace.id,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.location.token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            marketplaceScopes: responseJson,
            isScopesFetched: true,
          });
          console.log("Marketplace Scopes Response: ", responseJson);
          this.convertMarketplaceScopesToOptions(responseJson)
        })
        .catch((error) => {
          console.log(error);
        });

  }
  convertMarketplaceScopesToOptions = marketplaceScopes => {
    console.log("Inside convertMarketplaceScopesToOptions")
    
    marketplaceScopes = marketplaceScopes.data.map(ms => ({label: ms.scopeName, value: ms.id}))
    console.log("Converted MarketplaceScopes", marketplaceScopes)
    this.setState({
      marketplaceScopes: marketplaceScopes
    })
    return marketplaceScopes
  }

  onScopesChange = selectedScopes => {
    console.log("selected scopes: ", selectedScopes)
    const value = [];
    console.log("Before: ", selectedScopes)
    selectedScopes.map(ss => value.push(ss.label) )
    console.log("value: ", value)
    console.log("After: ", selectedScopes)
    this.setState({
      selectedScopeValues: selectedScopes || [],
      selectedScopeNames: value || []
    })
  }

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    console.log("====> ", value);
    this.setState({ selectedScopeValues: value });
  };

  loadScopes = async (inputText, callback) => {
    const response = await fetch(
      API_BASE_URL +
        "marketplace-field/get-marketplace-scopes-by-marketplace-id/" +
        this.props.location.marketplace.id,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.location.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          marketplaceScopes: responseJson,
        });
        console.log("Response: ", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
      const json = await this.state.marketplaceScopes.json();
     // callback(json.map( i => {label: i.scopeName}))  

  }

  validateRedirectURL = (redirectURL) => {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    if(!redirectURL || /^\s*$/.test(redirectURL)){
      return true;
    }
    if (redirectURL.includes('localhost') || redirectURL.includes('127.0. 0.1')){
      return true;
    }
    return urlRegex.test(String(redirectURL).toLowerCase())
  } 

  validateInputs = () => {
    if(!this.state.appSlug){
      console.log("App Slug must not be empty.")
      this.setState({
        isError: true,
        errorMessage: ` ${this.state.marketplaceField.data.appSlug} must not be empty.`
      })
      return false;
  } else if(!this.state.clientId){
    this.setState({
      isError: true,
      errorMessage: ` ${this.state.marketplaceField.data.clientId} must not be empty.`
    })
    return false;
} else if(!this.state.secretId){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.secretId} must not be empty.`
  })
  return false;
}else if(!this.state.appId){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.appId} must not be empty.`
  })
  return false;
}else if(this.state.selectedScopeValues.length <= 0){
  this.setState({
    isError: true,
    errorMessage:` ${this.state.marketplaceField.data.scopes} must not be empty.`
  })
  return false;
}else if (!this.validateRedirectURL(this.state.redirectURL)){
  this.setState({
    isError: true,
    errorMessage:` Invalid Redirect URL. Prefix with https/http required.`
  })
  return false;
}
return true;
  
}

  onAddMarketplaceNextClick = () => {
    function hasUpperCase(str) {
      return str.toLowerCase() !== str;
  }
    
    if(!this.validateInputs()){
      window.scrollTo(0, 0);
      return;
    }
    if(hasUpperCase(this.state.appSlug)){
      console.log("App Slug must be in all lower case.")
      this.setState({
        isError: true,
        errorMessage: "App Slug must be in all lower case."
      })
      window.scrollTo(0, 0);
      return;
    }

    if((/\s/.test(this.state.appSlug))){
      console.log("App Slug must be without any space character.")
      this.setState({
        isError: true,
        errorMessage: "App Slug must be without any space character."
      })
      window.scrollTo(0, 0);
      return;
    }

    this.setState({
      isError: false
    })

    let request = {
      appId: this.state.appId,
      appSlug: this.state.appSlug,
      clientId: this.state.clientId,
      secretId: this.state.secretId,
      redirectURL: this.state.redirectURL,
      scopeValues: this.state.selectedScopeNames.toString(),
      saasOrganisation: {
        id: this.props.location.createOrgResponse.data.id,
      },
      marketplace: {
        id: this.props.location.marketplace.id,
      },
    };

    console.log("create-saas-org-marketplace Request: ", JSON.stringify(request));

    fetch(API_BASE_URL + "saas-org-marketplace/create-saas-org-marketplace", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.location.token}`,
      },
      body: JSON.stringify(request),
    })
      .then(
        (response) => {
          if (response.ok) {
            this.setState({
              message: "SaaS Org Marketplace created successfully.",
            });
          }
          return response.json();
        },
        (error) => console.log("An error occurred.", error)
      )
      .then((responseJson) => {
        this.setState({
          createSaaSOrgMarketplaceResponse: responseJson,
        });
      })
      .then(() => {
        console.log("Response: ", this.state.createSaaSOrgMarketplaceResponse);

        this.props.history.push({
          pathname: "/edit-marketplace/",
          createOrgResponse: this.props.location.createOrgResponse,
          marketplace: this.props.location.marketplace,
          createSaaSOrgMarketplaceResponse: this.state
            .createSaaSOrgMarketplaceResponse,
          token: this.props.location.token,
          marketplaceScopes: this.state.marketplaceScopes
        });
      });
  };

  render() {

    const errorStyle = {
      color: 'red',
    };

    return (
      <div>
        <main>
          <h1>Add Marketplace</h1>

          {this.state.dataFetched === true && this.state.isScopesFetched === true ? (
            <div>
              <FormGroup>
              {this.state.isError === true && (
                  <div>
              <FormControl>
              <FormHelperText id="generic-help-text" style={errorStyle}>
                  {this.state.errorMessage}
               </FormHelperText>
               </FormControl>
               <br/><br/>
               </div>
              )}
                <FormControl>
              
                  <InputLabel htmlFor="app-slug">
                    {this.state.marketplaceField.data.appSlug}
                  </InputLabel>
                  <Input
                    id="app-slug"
                    aria-describedby="app-slug-help-text"
                    value={this.state.appSlug}
                    onChange={(e) => this.setState({ appSlug: e.target.value })}
                  />
                  <FormHelperText id="app-slug-help-text">
                    Please provide a short and memorable desciption for the app,
                    e.g. hubspot_dev or hbDev (no spaces and all lower case)
                  </FormHelperText>
                  {/* {this.state.isError === true && (
              <FormHelperText id="app-slug-help-text" style={errorStyle}>
                  {this.state.errorMessage}
               </FormHelperText>
              )} */}
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="client-id">
                    {this.state.marketplaceField.data.clientId}
                  </InputLabel>
                  <Input
                    id="client-id"
                    aria-describedby="client-id-help-text"
                    value={this.state.clientId}
                    onChange={(e) =>
                      this.setState({ clientId: e.target.value })
                    }
                  />
                  <FormHelperText id="client-id-help-text">
                    Please provide the{" "}
                    {this.state.marketplaceField.data.clientId} you have got
                    from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="secret-id">
                    {this.state.marketplaceField.data.secretId}
                  </InputLabel>
                  <Input
                    id="secret-id"
                    aria-describedby="secret-id-help-text"
                    value={this.state.secretId}
                    onChange={(e) =>
                      this.setState({ secretId: e.target.value })
                    }
                  />
                  <FormHelperText id="client-id-help-text">
                    Please provide the{" "}
                    {this.state.marketplaceField.data.secretId} you have got
                    from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="app-id">
                    {this.state.marketplaceField.data.appId}
                  </InputLabel>
                  <Input
                    id="app-id"
                    aria-describedby="app-id-help-text"
                    value={this.state.appId}
                    onChange={(e) => this.setState({ appId: e.target.value })}
                  />
                  <FormHelperText id="app-id-help-text">
                    Please provide the {this.state.marketplaceField.data.appId}{" "}
                    you have got from {this.props.location.marketplace.name}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel htmlFor="redirect-url">
                    Redirect URL
                  </InputLabel>
                  <Input
                    id="redirect-url"
                    aria-describedby="redirect-url-help-text"
                    value={this.state.redirectURL}
                    onChange={(e) => this.setState({ redirectURL: e.target.value })}
                  />
                 
                </FormControl>
                <br />
                <br />
                <FormControl>
                  <InputLabel shrink htmlFor="select-multiple-scopes">
                    {this.state.marketplaceField.data.scopes}
                  </InputLabel>
                  
                <Select
                        isMulti
                        value={this.state.selectedScopeValues}
                        onChange={this.onScopesChange}
                        placeholder={'Enter Scopes Here!'}
                        options ={this.state.marketplaceScopes}
                    />


                  {/* <Select
                    multiple
                    native
                    aria-describedby="scopes-help-text"
                    value={this.state.selectedScopeValues}
                    onChange={this.handleChangeMultiple}
                    inputProps={{
                      id: "select-multiple-scopes",
                    }}
                  >
                    <FormHelperText id="scopes-help-text">
                      Multiple sccopes can be selected.
                    </FormHelperText>

                    {this.state.marketplaceField.data.scopeValues
                      .split(",")
                      .map((scopeValue) => (
                        <option key={scopeValue} value={scopeValue}>
                          {scopeValue}
                        </option>
                      ))}
                  </Select> */}
                </FormControl>

                <br />
                <br />    
                <FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onAddMarketplaceNextClick}
                  >
                    Finish
                  </Button>
                </FormControl>
              </FormGroup>

              <br />
              <h2>{this.state.message}</h2>
            </div>
          ) : (
            <div></div>
          )}
        </main>
      </div>
    );
  }
}

export default withRouter(AddMarketplace);