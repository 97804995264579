import React from "react";
import StripePaymentForm from "../components/stripe-payment-form";
import { withRouter } from "react-router-dom";

function billing(props) {
  console.log("in billing");

  return (
    <div>
      <StripePaymentForm tokens={props.location.tokens} />
    </div>
  );
}

export default withRouter(billing);
