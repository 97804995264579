import { NavLink } from 'react-router-dom';
import React from 'react';
import useGAEventTracker from './use-ga-event-tracker';

const MainNav = () => {
  const GAEventTracker = useGAEventTracker('Nav Bar Links');

  return (

  <div className="navbar-nav mr-auto">
    <NavLink
      to="/"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
    >
      Home
    </NavLink>
    <NavLink
      to="/organisation"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Organisation Clicked")}
    >
      Organisation
    </NavLink>
    <NavLink
      to="/marketplaces"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Platforms Clicked")}
    >
      Platforms
    </NavLink>
    <NavLink
      to="/connected-users"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Connected Users Clicked")}
    >
      Users
    </NavLink>
    <NavLink
      to="/creds"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Creds Clicked")}
    >
      Creds
    </NavLink>
    <NavLink
      to="/billing"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Billing Clicked")}
    >
      Billing
    </NavLink>
    <a
      target="_blank" rel="noopener noreferrer"
      href="https://docs.stackgo.io"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Documentation Clicked")}
    >
      Docs
    </a>
    <a
      target="_blank" rel="noopener noreferrer"
      href="https://stackgoworkspace.slack.com/"
      exact
      className="nav-link"
      activeClassName="router-link-exact-active"
      onClick={e => GAEventTracker("Slack Clicked")}
    >
      Slack
    </a>
 
  </div>
  )
};

export default MainNav;
