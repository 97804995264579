import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE_URL } from "../util/constants";
import { Button } from "@material-ui/core";
import { Loading } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

function Settings(props) {

  const classes = useStyles();
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, picture, email } = user;
  const [integrationsResponse, setIntegrationsResponse] = useState("");
  const [saasAdminResponse, setSaasAdminResponse] = useState("");
  const [marketplace, setMarketplace] = useState("");
  const [saasOrgMarketplaceResponse, setSaasOrgMarketplaceResponse] = useState("");
  const [marketplaceScopesResponse, setMarketplaceScopesResponse] = useState("");
  const [onSaaSAdminLoginResponse, setOnSaaSAdminLoginResponse] = useState("");
  const [saasOrgSgApiCredsResponse, setSaasOrgSgApiCredsResponse] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [sgCredsFetched, setSgCredsFetched] = useState(false);
  const [secretShown, setSecretShown] = useState(false);
  const [clientShown, setClientShown] = useState(false);
  const toggleSecretVisiblity = () => {
    setSecretShown(secretShown ? false : true);
  };
  const toggleClientVisiblity = () => {
    setClientShown(clientShown ? false : true);
  };

  
  let saasAdminResponseObj = null;
  let marketplaceObj = null; 
  let saasOrgMarketplaceResponseObj = null;
  let marketplaceScopesResponseObj = null;
  
  const eye = <FontAwesomeIcon icon={faEye} />;
  const copy = <FontAwesomeIcon icon={faPaste} />;
  

  const gettingUserInfo = async () => {
    console.log("env: ", API_BASE_URL);
    if (user !== undefined) {
      const token = await getAccessTokenSilently();
      /** 
          if admin not saved then save it
          check if user linked to org 
      */
      let request = {
        email: user.email,
        nickname: user.nickname,
      };
      fetch(API_BASE_URL + "saas-admin/on-saas-admin-login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(
            "saas-admin/on-saas-admin-login Response: ",
            responseJson
          );
          setOnSaaSAdminLoginResponse(responseJson)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const getIntegrations = async () => {
    const token = await getAccessTokenSilently();
    fetch(API_BASE_URL + `saas-org-marketplace/get-marketplaces-with-appslug-by-email/${email}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-org-marketplace/get-marketplaces-by-email/ Response: ",
          responseJson
        );
        setIntegrationsResponse(responseJson)
        
      }).then(() => setDataFetched(true))
      .catch((error) => {
        console.log(error);
      });
  }

  const getSaaSOrgSgApiCreds = async () => {
    const token = await getAccessTokenSilently();
    fetch(API_BASE_URL + `saas-org-sgapi-creds/get-saas-org-sg-api-creds-by-email/${email}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-org-sgapi-creds/get-saas-org-sg-api-creds-by-email/ Response: ",
          responseJson
        );
        setSaasOrgSgApiCredsResponse(responseJson)
        
      }).then(() => setSgCredsFetched(true))
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getIntegrations();
    gettingUserInfo();
    getSaaSOrgSgApiCreds();
  }, [user])

  if ( user !== undefined && !dataFetched) {
    return <Loading />;
  }

  const getSaaSOrgMarketPlace = async (orgId, marketplaceId, appSlug, token) => {
    console.log("getSaaSOrgMarketPlace orgId: ", orgId)
    console.log("getSaaSOrgMarketPlace marketplaceId: ", marketplaceId)
    console.log("getSaaSOrgMarketPlace appSlug: ", appSlug)

    await fetch(API_BASE_URL + `saas-org-marketplace/get-saas-org-marketplace-by-orgId-marketplaceId-and-appSlug/${orgId}/${marketplaceId}/${appSlug}`, {
      method: 'get',
      headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
  })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log("#@$#@$@##@$@ saasOrgMarketplaceResponse: ", responseJson)

    setSaasOrgMarketplaceResponse(responseJson)
    setSaasOrgMarketplaceResponse((state) => {
      saasOrgMarketplaceResponseObj = state
      return state;
  })
  }).catch(
  error => {
      console.log(error)
  })
}

const getMarketplaceScopes = async (marketplaceId, token) => {
  console.log("################# getMarketplaceScopes marketplaceId: ", marketplaceId)

await fetch(API_BASE_URL + `marketplace-scope/get-marketplace-scopes-by-marketplace-id/${marketplaceId}`, {
    method: 'get',
    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
})
.then((response) => response.json())
.then((responseJson) => {
  console.log("################## getMarketplaceScopes: ", responseJson)
  const convertedMarketplaceScopes = responseJson.data.map(ms => ({label: ms.scopeName, value: ms.id}))
    console.log("Converted MarketplaceScopes", convertedMarketplaceScopes )
    setMarketplaceScopesResponse((state) => {
      marketplaceScopesResponseObj = state
      return state;
  })
}).catch(
error => {
    console.log(error)
})
}

const convertMarketplaceScopesToOptions = responseJson => {
    console.log("Inside convertMarketplaceScopesToOptions: ", responseJson)
    
    const convertedMarketplaceScopes = responseJson.data.map(ms => ({label: ms.scopeName, value: ms.id}))
    console.log("Converted MarketplaceScopes", convertedMarketplaceScopes )
    setMarketplaceScopesResponse(convertedMarketplaceScopes)
    setMarketplaceScopesResponse((state) => {
      marketplaceScopesResponseObj = state
      return state;
  })
    return convertedMarketplaceScopes;
}


  const onAddNewIntegrationClick = async () => {
    const token = await getAccessTokenSilently();

    await fetch(API_BASE_URL + `saas-admin/get-saas-admin-by-email/${email}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-admin/get-saas-admin-by-email Response: ",
          responseJson
        );
        setSaasAdminResponse(responseJson)
        
        setSaasAdminResponse((state) => {
          saasAdminResponseObj = state
          return state;
      })

      }).then(() => {
        const createOrgResponse = {
          data: saasAdminResponseObj.data.saasOrganisation
        }
        props.history.push({
          pathname: '/select-marketplace/',
          response: createOrgResponse,
          token: token,
          user: user
        })
      })
      .catch((error) => {
        console.log(error);
      });  
  }

  const onMarketplaceClick = async (marketplace) => {
    console.log("Marketplace click with: ", marketplace)
    const token = await getAccessTokenSilently();
    await fetch(API_BASE_URL + `saas-admin/get-saas-admin-by-email/${email}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-admin/get-saas-admin-by-email Response: ",
          responseJson
        );
        setSaasAdminResponse(responseJson)
        
        setSaasAdminResponse((state) => {
          saasAdminResponseObj = state
          return state;
      })

      setMarketplace(marketplace)
      setMarketplace((state) => {
        marketplaceObj = state
        return state;
    })
      })
      .then(async () => {      
        await getSaaSOrgMarketPlace(saasAdminResponseObj.data.saasOrganisation.id, marketplaceObj.marketplaceId, marketplaceObj.appSlug, token)
      })
      .then(async () => {      
        await fetch(API_BASE_URL + `marketplace-scope/get-marketplace-scopes-by-marketplace-id/${marketplaceObj.marketplaceId}`, {
          method: 'get',
          headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("################## getMarketplaceScopes: ", responseJson)
        convertMarketplaceScopesToOptions(responseJson)
      }).catch(
      error => {
          console.log(error)
      })
      })
      .then(() => {
        const createOrgResponse = {
          data: saasAdminResponseObj.data.saasOrganisation
        }
    console.log("componentDidMount settings : CreateOrg response: ", createOrgResponse)
    console.log("componentDidMount settings : Marketplace response: ", marketplaceObj)
    console.log("componentDidMount settings : MarketplaceScopes response: ", marketplaceScopesResponseObj)
    console.log("componentDidMount settings : createSaaSOrgMarketplaceResponse response: ", saasOrgMarketplaceResponseObj)

    const marketplaceFinal = {
      id: marketplaceObj.marketplaceId,
      name: marketplaceObj.marketplaceName
    }
    
    props.history.push({
            pathname: '/edit-marketplace/',
            createOrgResponse: createOrgResponse ,
            marketplace: marketplaceFinal,
            createSaaSOrgMarketplaceResponse: saasOrgMarketplaceResponseObj,
            token: token,
            convertedMarketplaceScopes: marketplaceScopesResponseObj,
          })
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const inputStyle = {
    border: 'none',
  };


  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>{name}</h2>
          <p className="lead text-muted">{email}</p>
        </div>
      </div>

      <div className="row">
        {dataFetched === true && ( integrationsResponse !== undefined && integrationsResponse !== null && integrationsResponse.data?.length > 0) ? (
          
           <div>

           <div className="row">
        
        Looking for new Platform Integration? &nbsp;&nbsp;  <Button onClick={() => onAddNewIntegrationClick()}  color="primary" variant="contained"  >Add Now</Button>   
          
        </div>
        <br />
             <br />
             
               Your Integrations:
               <div>
            {integrationsResponse.data.map((integration, i) => (
                <div  key={i}>
                  <br />

                  <Card className={classes.root}>
      <CardActionArea onClick={() => onMarketplaceClick(integration)} >
        <CardMedia
          component="img"
          alt={integration.name}
          height="140"
          image={integration.marketplaceImageUrl}
          title={integration.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {integration.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary"  onClick={() => onMarketplaceClick(integration)} >
          View
        </Button>
      </CardActions>
    </Card>

                </div>
            ) )}
             </div>
           
            </div>
           
                ) : (
                    <div></div>
                )

  
                }
      </div>
      <br /> <br />

 
      <br/>

    </div>
  );
};

export default Settings;
