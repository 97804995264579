import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  FormGroup,
  Button,
} from "@material-ui/core";

function SaaSCreds(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  const {  email } = user;
  const [saasOrgSgApiCredsResponse, setSaasOrgSgApiCredsResponse] = useState("");
  const [sgCredsFetched, setSgCredsFetched] = useState(false);
  const [secretShown, setSecretShown] = useState(false);
  const [clientShown, setClientShown] = useState(false);


  useEffect(() => {
    getSaaSOrgSgApiCreds();
  }, [user]);

  const eye = <FontAwesomeIcon icon={faEye} />;
  const copy = <FontAwesomeIcon icon={faPaste} />;

  const toggleSecretVisiblity = () => {
    setSecretShown(secretShown ? false : true);
  };
  const toggleClientVisiblity = () => {
    setClientShown(clientShown ? false : true);
  };

  const getSaaSOrgSgApiCreds = async () => {
    const token = await getAccessTokenSilently();
    fetch(API_BASE_URL + `saas-org-sgapi-creds/get-saas-org-sg-api-creds-by-email/${email}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-org-sgapi-creds/get-saas-org-sg-api-creds-by-email/ Response: ",
          responseJson
        );
        setSaasOrgSgApiCredsResponse(responseJson)
        
      }).then(() => setSgCredsFetched(true))
      .catch((error) => {
        console.log(error);
      });
  }

  const inputStyle = {
    border: 'none',
    width: 310
  };
  return (
    <div>
        {user !== undefined &&  (
      <main>
      <h1>SaaS Creds</h1>
      
      <br />
                    <br />

                    <div className="row">
        {sgCredsFetched === true && ( saasOrgSgApiCredsResponse !== undefined && saasOrgSgApiCredsResponse !== null) ? (
         
          <div>
            <div>
          <FormControl>
          <InputLabel htmlFor="org-name">Client Id</InputLabel>
          <Input
            id="org-name"
            style={inputStyle} 
            aria-describedby="org-name-help-text"
            type={clientShown ? "text" : "password"}
            value={ saasOrgSgApiCredsResponse.data.sgClientId }
          />
           <i className="sg-icon-eye" onClick={toggleClientVisiblity}>{eye}</i>{" "}&nbsp;
           <i className="sg-icon-copy" onClick={() => {navigator.clipboard.writeText(saasOrgSgApiCredsResponse.data.sgClientId)}}>{copy}</i>{" "}
           
        </FormControl>
        </div>
        <div>
        <FormControl>
          <InputLabel htmlFor="secret-id">Secret Id</InputLabel>
          <Input
            id="secret-id"
            style={inputStyle} 
            type={secretShown ? "text" : "password"}
            value={ saasOrgSgApiCredsResponse.data.sgSecretId }
          />
           <i className="sg-icon-eye" onClick={toggleSecretVisiblity}>{eye}</i>{" "}&nbsp;
           <i className="sg-icon-copy" onClick={() => {navigator.clipboard.writeText(saasOrgSgApiCredsResponse.data.sgSecretId)}}>{copy}</i>{" "}
        </FormControl>
        </div>

        <div>
          <FormControl>
          <InputLabel htmlFor="audience">Audience</InputLabel>
          <Input
            id="audience"
            style={inputStyle} 
            aria-describedby="audience-help-text"
            value={ saasOrgSgApiCredsResponse.data.audience }
          />
           <i className="sg-icon-copy" onClick={() => {navigator.clipboard.writeText(saasOrgSgApiCredsResponse.data.audience)}}>{copy}</i>{" "}
           
        </FormControl>
        </div>
        <br/>
        <div>
          <FormControl>
          <InputLabel htmlFor="auth-url">Auth URL</InputLabel>
          <Input
            id="auth-url"
            style={inputStyle} 
            aria-describedby="auth-url-help-text"
            value={ saasOrgSgApiCredsResponse.data.authUrl }
          />
           <i className="sg-icon-copy" onClick={() => {navigator.clipboard.writeText(saasOrgSgApiCredsResponse.data.authUrl)}}>{copy}</i>{" "}
           
        </FormControl>
        </div>
        <br/>
        <div>
          <FormControl>
          <InputLabel htmlFor="api-url">API URL</InputLabel>
          <Input
            id="api-url"
            style={inputStyle} 
            aria-describedby="api-url-help-text"
            value={ API_BASE_URL }
          />
           <i className="sg-icon-copy" onClick={() => {navigator.clipboard.writeText(API_BASE_URL)}}>{copy}</i>{" "}
           
        </FormControl>
        </div>
        <br/>  <br/>
          </div>
          
       
                ) : (
                    <div></div>
                )

  
                }
</div>

      </main>
      )}
    </div>
  );
}

export default withRouter(SaaSCreds);
