import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const HomeContent = () => (
  <div className="next-steps">
    <h2 className="my-5 text-center">What can I do next?</h2>

    <div className="row">
      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://stackgo.io/saas-marketplace-finder/"
          >
            <FontAwesomeIcon icon={faLink} className="mr-2" />
            SaaS Platform Finder
          </a>
        </h6>
        <p>
          Search our SaaS Platform Finder to determine the right platforms for
          your software. Quickly compare commercial and technical differences.
          You’ll find many platforms you know and some absolute gems you won’t.
        </p>
      </div>

      <div className="col-md" />

      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://stackgo.io"
          >
            <FontAwesomeIcon icon={faLink} className="mr-2" />
            Software vendors love StackGo
          </a>
        </h6>
        <p>
          Productized Integrations for B2B Software Companies Save $10k+ and 3+
          weeks to build and maintain 1:many SaaS integrations.
        </p>
      </div>
    </div>
  </div>
);

export default HomeContent;
