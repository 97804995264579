import React, { useState, useEffect, forwardRef } from "react";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { alpha } from "@material-ui/core/styles";

function ConnectedUsers(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  const { email } = user;
  const [connectedUsersResponse, setConnectedUsersResponse] = useState("");
  const [connectedUsersFetched, setConnectedUsersFetched] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    getConnectedUsers();
  }, [user]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    { title: "User", field: "userForeignIdentifier" },
    { title: "App Slug", field: "saasOrgMarketplace.appSlug" },
    { title: "Expiry", field: "expiry" },
    { title: "Status", field: "status" },
    { title: "Created At", field: "createdAt" },
    { title: "Refresh Token", field: "refreshToken" },
  ];

  const getConnectedUsers = async () => {
    const token = await getAccessTokenSilently();
    setToken(token);
    await fetch(
      API_BASE_URL +
        `saas-org-user-ui/get-connected-users?email=${email}&page=1&limit=5`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(
          "saas-org-user-ui/get-connected-users Response: ",
          responseJson
        );
        setConnectedUsersResponse(responseJson);
        setConnectedUsersFetched(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {user !== undefined && connectedUsersFetched && (
        <MaterialTable
          icons={tableIcons}
          title="Connected Users"
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              console.log(query);
              let url =
                API_BASE_URL +
                `saas-org-user-ui/get-connected-users?email=${email}`;
              url += `&page=${query.page + 1}&limit=${query.pageSize}`;
              fetch(url, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((response) => response.json())
                .then((response) => {
                  setConnectedUsersResponse(response.data);
                  resolve({
                    data: connectedUsersResponse.data || response.data,
                    page: query.page, // current page number
                    totalCount: response.totalCount,
                  });
                });
            })
          }
          editable={{
            onRowDelete: (row) =>
              new Promise((resolve, reject) => {
                console.log(row);
                let url =
                  API_BASE_URL +
                  `saas-org-user-ui/delete-user/${row.saasOrgMarketplace.appSlug}?userForeignIdentifier=${row.userForeignIdentifier}&saasOrgId=${row.saasOrgMarketplace.saasOrganisation.id}`;
                console.log("URL: ", url);
                fetch(url, {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                })
                  .then((response) => response.json())
                  .then((response) => {
                    console.log(response);
                    resolve();
                  });
              }),
          }}
          options={{
            //actionsColumnIndex: -1
            //filtering: true,
            search: true,
          }}
        />
      )}
    </div>
  );
}

export default withRouter(ConnectedUsers);
