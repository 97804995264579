import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthProvider from '../components/auth-provider';

function addAuthProvider(props) {
  console.log('in auth prov');
  return (
    <div>
      <AuthProvider />
    </div>
  );
}

export default withRouter(addAuthProvider);
