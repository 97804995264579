import React, { useState, useEffect } from "react";
import StripePayment from "../components/stripe-payment";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { BILLING_BASE_URL } from "../util/constants";
import StripeSubscriptionButton from "./stripe-subscription-button";
import StripeManageSubscription from "./stripe-manage-subscription";
import { useAuth0 } from "@auth0/auth0-react";

function StripePaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { getAccessTokenSilently, user } = useAuth0();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const token = await getAccessTokenSilently();
    const email = user.email;
    console.log("from handle submist", email);
    const res = await fetch(BILLING_BASE_URL + "create-payment-intent", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(email),
    });
    console.log(res);
    const resJson = await res.json();
    console.log(resJson);

    const clientSecret = resJson["clientSecret"];

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    // Use your card Element with other Stripe.js APIs
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (result.error) {
      console.log("[error]", result.error);
    } else {
      console.log(result.paymentIntent.status);
      console.log("money in bank");
    }
  };

  const [alreadySubscribed, setSubscription] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getSubscriptionStatus() {
      const { email } = user;
      const token = await getAccessTokenSilently();
      const response = await fetch(
        BILLING_BASE_URL +
          `check-subscription-status?email=${encodeURIComponent(email)}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resJson = await response.json();
      console.log(resJson);
      setSubscription(resJson.subscriptionStatus);
      setLoaded(true);
    }
    getSubscriptionStatus();
  }, [getAccessTokenSilently, user]);

  if (!loaded) {
    // doo
    return (
      <div>
        <p>Checking Subscription status....</p>
      </div>
    );
  } else {
    return (
      <div>
        {alreadySubscribed ? (
          <StripeManageSubscription />
        ) : (
          <StripeSubscriptionButton />
        )}
      </div>
    );
  }
}

export default StripePaymentForm;
