import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  FormGroup,
  Button,
} from "@material-ui/core";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";

function CompleteProfile(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [linkSaaSAdminToOrgResponse, setLinkSaaSAdminToOrgResponse] = useState(
    ""
  );
  const [onSaaSAdminLoginResponse, setOnSaaSAdminLoginResponse] = useState("");

  const onProfileNextClick = async () => {
    let createOrgResponse = "";
    let request = {
      orgName: orgName,
      email: email,
      phone: phone,
      status: "ACTIVE",
    };
    const token = await getAccessTokenSilently();
    console.log(JSON.stringify(request));
    fetch(API_BASE_URL + "saas-org/create-saas-org", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    })
      .then(
        (response) => {
          if (response.ok) {
            setMessage("SaaS Organisation created successfully.");
          }
          return response.json();
        },
        (error) => console.log("An error occurred.", error)
      )
      .then((responseJson) => {
        console.log("responseJSON: ", responseJson);
        setResponse(responseJson);
        setResponse((state) => {
          console.log("####### res: ", state);
          createOrgResponse = state;
          return state;
        });

        let linkSaaSAdminToOrgRequest = {
          email: user.email,
          orgId: responseJson.data.id,
        };
        console.log("linkSaaSAdminToOrgRequest: ", linkSaaSAdminToOrgRequest);
        fetch(API_BASE_URL + "saas-admin/link-saas-admin-to-org", {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(linkSaaSAdminToOrgRequest),
        })
          .then(
            (response) => {
              if (response.ok) {
              }
              return response.json();
            },
            (error) => console.log("An error occurred.", error)
          )
          .then((responseJson) => {
            setLinkSaaSAdminToOrgResponse(responseJson);

            props.history.push({
              pathname: "/select-marketplace",
              response: createOrgResponse,
              linkSaaSAdminToOrgResponse: responseJson,
              token: token,
            });
            console.log(
              "linkSaaSAdminToOrgResponse: ",
              linkSaaSAdminToOrgResponse
            );
          })
          .then(() => {});
      })
      .then(() => {
        console.log("Response: ", response);

        setOrgName("");
        setEmail("");
        setPhone("");
      });
  };

  const gettingUserInfo = async () => {
    console.log("env: ", API_BASE_URL);
    if (user !== undefined) {
      const token = await getAccessTokenSilently();
      /** 
         * if admin not saved then save it
           check if user linked to org 
        */
      let request = {
        email: user.email,
        nickname: user.nickname,
      };
      fetch(API_BASE_URL + "saas-admin/on-saas-admin-login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(
            "saas-admin/on-saas-admin-login Response: ",
            responseJson
          );
          setOnSaaSAdminLoginResponse(responseJson);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    gettingUserInfo();
    /** 
    By providing [user] as a second argument, we are just telling “useEffect()” to run only if the certain value (user) has changed between the component re-renders.
    */
  }, [user]);

  return (
    <div>
      {onSaaSAdminLoginResponse.isAdminLinkedToOrg === true &&
      user !== undefined ? (
        <div>
          <Redirect to="/" />;
        </div>
      ) : (
        <main>
          <h1>Complete Profile</h1>
          <FormGroup>
            <FormControl>
              <InputLabel htmlFor="org-name">Organisation Name</InputLabel>
              <Input
                id="org-name"
                aria-describedby="org-name-help-text"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
              <FormHelperText id="org-name-help-text"></FormHelperText>
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                aria-describedby="email-help-text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormHelperText id="email-help-text">
                We'll never share your email id.
              </FormHelperText>
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="phone">Phone</InputLabel>
              <Input
                id="phone"
                aria-describedby="phone-help-text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <FormHelperText id="phone-help-text">
                We'll never share your phone number.
              </FormHelperText>
            </FormControl>
            <br />
            <br />
            <br />
            <br />
            <br />

            <FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={onProfileNextClick}
              >
                Next
              </Button>
            </FormControl>
          </FormGroup>
          <h2>{message}</h2>
        </main>
      )}
    </div>
  );
}

export default withRouter(CompleteProfile);
