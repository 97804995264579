import { Button } from "@material-ui/core";
import React from "react";
import { BILLING_BASE_URL } from "../util/constants";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";

function StripeManageSubscription(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { getAccessTokenSilently, user } = useAuth0();

  const redirectToStripe = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const token = await getAccessTokenSilently();
    const { email } = user;
    console.log("from redirect to stripe", email);
    console.log("tokens", token);

    const res = await fetch(
      BILLING_BASE_URL +
        "create-customer-portal-session" +
        `?email=${encodeURIComponent(email)}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    const resJson = await res.json();
    console.log(resJson);
    // do the redirect
    window.location.href = resJson.url;
  };

  return (
    <div>
      <p>Manage Subscription</p>
      <Button onClick={redirectToStripe}>Manage</Button>
    </div>
  );
}

export default StripeManageSubscription;
