import React from "react";

const logo = "https://stackgo.io/wp-content/uploads/2020/11/stackgo-logo-1.png";

const Hero = () => (
  <div className="text-center hero">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <p className="lead">
    Discover, integrate and optimise for multiple SaaS platforms in the one spot
StackGo makes it 10x faster and cheaper than connecting directly.
    </p>
  </div>
);

export default Hero;
