import React, { Fragment, useState, useEffect } from "react";

import { Hero, HomeContent } from "../components";
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE_URL } from "../util/constants";
import { withRouter } from "react-router-dom";


function Home(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [onSaaSAdminLoginResponse, setOnSaaSAdminLoginResponse] = useState("");

  let token = null;

  const gettingUserInfo = async () => {
    console.log("env: ", API_BASE_URL);
    if (user !== undefined) {
      token = await getAccessTokenSilently();
      console.log("token is: ", token)
      /** 
         * if admin not saved then save it
           check if user linked to org 
        */
      let request = {
        email: user.email,
        nickname: user.nickname,
      };
      fetch(API_BASE_URL + "saas-admin/on-saas-admin-login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          console.log(
            "saas-admin/on-saas-admin-login Response: ",
            responseJson
          );
          setOnSaaSAdminLoginResponse(responseJson)
          const createSaaSOrg = {
            data: responseJson.data.saasOrganisation
          }
          token = await getAccessTokenSilently();
          if(responseJson.isAdminLinkedToMarketplace === false){
            {props.history.push({
              pathname: "/select-marketplace",
              token: token,
              response:createSaaSOrg
            })}
          }


        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    gettingUserInfo();
    /** 
    By providing [user] as a second argument, we are just telling “useEffect()” to run only if the certain value (user) has changed between the component re-renders.
    */
  }, [user])

  return (
    <Fragment>
      {/* {(() => {
        console.log("onSaaSAdminLoginResponse --> ", onSaaSAdminLoginResponse)
        if (user !== undefined) {
          console.log("User obj: ", user);
          console.log("email:: ", user.email);
          localStorage.setItem("email", user.email);
        } else {
          localStorage.removeItem("email");
        }
        console.log("email: ", localStorage.getItem("email"));
      })()} */}
      <Hero />
      <hr />
      {onSaaSAdminLoginResponse.isAdminLinkedToMarketplace === false && user !== undefined ? (
        <div>
                {/* <Redirect to='/select-marketplace' />; */}
               
        </div>
      ) : (
        <div>
        </div>
      )}
      <HomeContent />
    </Fragment>
  );
}
export default withRouter(Home);
