import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_URL } from '../util/constants';
import {
  Input,
  InputLabel,
  FormGroup,
  Button,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../components/loading';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AuthProvider(props) {
  const { getAccessTokenSilently, user } = useAuth0();

  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [authProvider, setAuthProvider] = useState({
    audience: '',
    authProviderName: '',
    clientId: '',
    domain: '',
  });

  useEffect(() => {
    async function getAuthProvider() {
      const { email } = user;
      const token = await getAccessTokenSilently();
      const response = await fetch(
        API_BASE_URL + `saas-org-auth-provider/auth-provider?email=${email}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resJson = await response.json();
      console.log(resJson);
      setAuthProvider(resJson);
      setLoaded(true);
    }
    getAuthProvider();
  }, [getAccessTokenSilently, user]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    console.log('in handle change');
    const updateAuthProvider = authProvider;
    updateAuthProvider[e.target.name] = e.target.value;
    setAuthProvider({
      ...updateAuthProvider,
    });
  };

  const updateAuthProvider = async (event) => {
    console.log('in update auth provider');
    console.log('props are: ', props);
    // turn loading to true
    setLoaded(false);
    // post to backend
    const { email } = user;
    const token = await getAccessTokenSilently();
    const response = await fetch(
      API_BASE_URL + `saas-org-auth-provider/auth-provider`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: email,
          clientId: authProvider.clientId,
          domain: authProvider.domain,
          audience: authProvider.audience,
        }),
      }
    );
    const resJson = await response.json();
    console.log(resJson);
    setAuthProvider(resJson);
    setLoaded(true);

    // Set the authProvider
    setAuthProvider({
      domain: resJson.domain,
      audience: resJson.audience,
      clientId: resJson.clientId,
      authProviderName: resJson.authProviderName,
    });
    // setOpen to true
    setOpen(true);
  };

  if (!loaded) {
    // doo
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  } else {
    return (
      <div>
        <p>In Auth Prov</p>

        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert severity="success" onClose={handleClose}>
            You settings have been saved/updated.
          </Alert>
        </Snackbar>
        <div>
          <FormGroup>
            <InputLabel name="domain">Domain</InputLabel>
            <Input
              type="text"
              id="domain_label"
              name="domain"
              value={authProvider.domain}
              onChange={(e) => handleChange(e)}
            ></Input>
            <br />
            <InputLabel>Audience</InputLabel>
            <Input
              type="text"
              id="domain_text"
              name="audience"
              value={authProvider.audience}
              onChange={(e) => handleChange(e)}
            ></Input>
            <br />
            <InputLabel>Client ID</InputLabel>
            <Input
              type="text"
              name="clientId"
              value={authProvider.clientId}
              onChange={(e) => handleChange(e)}
            ></Input>
            <br />
            <InputLabel>Auth provider</InputLabel>
            <Input
              type="text"
              id="test comp"
              value="Auth0"
              readOnly={true}
            ></Input>
          </FormGroup>
        </div>
        {/* <AuthProviderForm authProvider={authProvider} /> */}
        <br />
        <button onClick={updateAuthProvider}>Save</button>
        <br />
        <br />
      </div>
    );
  }
}

function AuthProviderForm(props) {
  return (
    <div>
      <FormGroup>
        <InputLabel name="domain">Domain</InputLabel>
        <Input
          type="text"
          id="domain_label"
          name="domain"
          value={props.authProvider.domain}
        ></Input>
        <br />
        <InputLabel>Audience</InputLabel>
        <Input
          type="text"
          id="domain_text"
          name="audience"
          value={props.authProvider.audience}
        ></Input>
        <br />
        <InputLabel>Client ID</InputLabel>
        <Input
          type="text"
          id="client_id"
          value={props.authProvider.clientId}
        ></Input>
        <br />
        <InputLabel>Auth provider</InputLabel>
        <Input type="text" id="test comp" value="Auth0" readOnly={true}></Input>
      </FormGroup>
    </div>
  );
}

export default AuthProvider;
